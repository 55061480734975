import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdCheckCircle, MdEditDocument, MdLock, MdOutlinePlaylistAdd, MdOutlinePlaylistAddCheck } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useActions, useAppSelector } from '@core/store/hooks';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { selectCurrentDeal } from '@entities/office/deal/deal.selectrors';
import { getChanges } from '@entities/office/dealDocument/dealDocument.actions';
import { closeDoc, getAllDocModelsFromDealId, openDoc } from '@entities/office/dealDocument/dealDocument.api';
import { EDocModelTypesLabels } from '@entities/office/dealDocument/dealDocument.types';
import { useEditor } from '@entities/office/dealDocument/hooks/document.useEditor';
import { getURL } from '@shared/lib/getURL';
import { isExistAndNotEmpty } from '@shared/lib/objects';
import Loader from '@shared/ui/Loader';
import Logger from '@shared/lib/logger';
import { VscChromeClose } from 'react-icons/vsc';
import { IoAddCircleSharp } from 'react-icons/io5';

const ListDocs = ({ loadDocs, list, start, end, isDoneStatus = null }) => {
    const navigate = useNavigate();

    const { editorRole } = useEditor();
    const currentDeal = useAppSelector(selectCurrentDeal);
    const { setCurrentDeal } = useActions();

    const axiosPrivate = useAxiosPrivate();

    return list.slice(start, end).map((doc, idx) => {
        // return list.slice(start, end).map((doc, idx) => {
        const { lastChangesPartner, docIsDeclareClosed, isLastDeclorator } = getChanges(doc, editorRole);

        Logger.log({ idx, docIsDeclareClosed, isLastDeclorator, editorRole });

        return (isDoneStatus && idx + start > 4) || idx + start < 5 ? (
            <div
                key={idx}
                className="docs-header mt-3"
                onClick={async () => {
                    if (doc.isExist) {
                        navigate(getURL(appRoutes.OFFICE_DEAL_DOC_EDITOR), { state: { doc } });
                    } else {
                        const openedDoc = await openDoc(axiosPrivate, currentDeal?.id, doc.type);
                        navigate(getURL(appRoutes.OFFICE_DEAL_DOC_EDITOR), { state: { doc: openedDoc } });
                    }
                }}
                style={{ display: 'flex', height: '50px', cursor: 'pointer' }}
            >
                {doc.isExist && !doc.isClosed ? (
                    <>
                        <span
                            onClick={async (event) => {
                                event.stopPropagation();
                                const isCloseDoc = await closeDoc(axiosPrivate, currentDeal?.id, doc.type);
                                if (isCloseDoc) {
                                    loadDocs();
                                }
                            }}
                        >
                            <IconContext.Provider value={{ className: 'icon-15 red' }}>
                                <VscChromeClose />
                            </IconContext.Provider>
                        </span>

                        {/* <IconContext.Provider value={{ className: 'icon-15 blue' }}>
                            <MdEditDocument />
                        </IconContext.Provider> */}
                    </>
                ) : (
                    <>
                        {doc.isClosed ? (
                            <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                <MdCheckCircle />
                            </IconContext.Provider>
                        ) : (
                            <IconContext.Provider value={{ className: 'icon-15 blue' }}>
                                <IoAddCircleSharp />
                            </IconContext.Provider>
                        )}
                    </>
                )}
                <div
                    className="title"
                    style={
                        !doc.isExist && !doc.isClosed
                            ? {
                                  color: '#999',
                              }
                            : {}
                    }
                >
                    {EDocModelTypesLabels[idx + start]}
                </div>
                {!doc.isClosed ? (
                    lastChangesPartner.length && !docIsDeclareClosed ? (
                        <IconContext.Provider value={{ className: 'icon-15', style: { color: 'orange' } }}>
                            <MdOutlinePlaylistAdd />
                        </IconContext.Provider>
                    ) : docIsDeclareClosed && !isLastDeclorator ? (
                        <IconContext.Provider value={{ className: 'icon-15', style: { color: 'orange' } }}>
                            <MdOutlinePlaylistAddCheck />
                        </IconContext.Provider>
                    ) : null
                ) : null}
            </div>
        ) : (
            <div
                key={idx}
                className="docs-header mt-3"
                style={{
                    fontWeight: 500,
                    background: '#444',
                    boxShadow: 'none',
                    color: 'white',
                    height: '50px',
                    //@ts-ignore
                    // '-webkit-touch-callout': 'none' /* iOS Safari */,
                    // '-webkit-user-select': 'none' /* Safari */,
                    // '-khtml-user-select': 'none' /* Konqueror HTML */,
                    // '-moz-user-select': 'none' /* Old versions of Firefox */,
                    // '-ms-user-select': 'none' /* Internet Explorer/Edge */,
                    // 'user-select': 'none' /* Non-prefixed version, currently
                    //               supported by Chrome, Edge, Opera and Firefox */,
                }}
            >
                <IconContext.Provider value={{ className: 'icon-15 white' }}>
                    <MdLock />
                </IconContext.Provider>
                <div className="title">{EDocModelTypesLabels[idx + start]}</div>
            </div>
        );
    });
};

export default function OfficeDocumentsPage() {
    const axiosPrivate = useAxiosPrivate();
    const currentDeal = useAppSelector(selectCurrentDeal);

    const [currentDocs, setCurrentDocs] = useState([]);
    // const [isDoneStatus, setIsDoneStatus] = useState(true);
    const [isDoneStatus, setIsDoneStatus] = useState(false);

    const loadDocs = async () => {
        const res = await getAllDocModelsFromDealId(axiosPrivate, currentDeal?.id);
        if (res) {
            res?.sort((a, b) => a.type - b.type);
            res?.slice(0, 5).reduce((a, b) => a && b, true);
            setCurrentDocs(res);
            setIsDoneStatus(currentDeal.status > 2);
        }
    };

    useEffect(() => {
        loadDocs();
    }, []);

    return (
        <section id="sec-9" className="pt-4 pt-sm-5 py-lg-5 box p-4 p-xl-5">
            <Link to={getURL(appRoutes.OFFICE, appRoutes.DEALS)} className="fs-12">
                <span className="green fs-15 me-2">⟵</span>
                Назад
            </Link>

            {currentDeal && (
                <h1 className="dark-blue text-center" style={{ margin: '10px' }}>
                    {currentDeal?.cargo
                        ? `Груз №${currentDeal?.cargo.id} ${currentDeal?.cargo?.loadings?.at(-1)?.town} -
                ${currentDeal?.cargo?.unloadings?.at(-1)?.town}`
                        : `Маршрут №${currentDeal?.route.id} ${currentDeal?.route.fromRoute} - ${currentDeal?.route.toRoute}`}
                </h1>
            )}

            {isExistAndNotEmpty(currentDocs) ? (
                <div className="deal-documents">
                    <div className="deal-document">
                        <ListDocs loadDocs={loadDocs} list={currentDocs} start={0} end={4} />
                    </div>
                    <div className="deal-document">
                        <ListDocs
                            loadDocs={loadDocs}
                            list={currentDocs}
                            start={4}
                            end={8}
                            isDoneStatus={isDoneStatus}
                        />
                    </div>
                </div>
            ) : (
                <div className="w-100 d-flex justify-content-center">
                    <Loader color="#545454" />
                </div>
            )}
        </section>
    );
}
